import {WhitelabelEvent, WhitelabelEventWithPayload} from "../events/Events";
import {EventBus} from "../events/EventBus";

let httpClient: typeof fetch;
let eventBus: EventBus;

export type EventConstructor = new (payload?: any) => WhitelabelEvent;

export interface SuccessEvent {
    eventClass: EventConstructor,
    payload?: {}
}

export interface ErrorEvent {
    eventClass: EventConstructor,
    payload?: {}
}

interface PostPayload {
    url: string,
    body: {},
    formUrlencoded?: boolean,
    successEvent?: SuccessEvent,
    errorEvent?: ErrorEvent,
}

export class PostEvent implements WhitelabelEventWithPayload<PostPayload> {
    payload: PostPayload;
    type: string;
    static readonly TYPE: string = 'http-post';

    constructor(payload: PostPayload) {
        this.type = PostEvent.TYPE;
        this.payload = payload;
    }
}

export function httpPostEventHandler(event: WhitelabelEvent) {
    const {payload: {url, body, formUrlencoded = false, successEvent, errorEvent}} = event as PostEvent;
    const postBody = formUrlencoded
        ? (new URLSearchParams(body)).toString()
        : JSON.stringify(body)

    const contentType = formUrlencoded
        ? 'application/x-www-form-urlencoded;charset=UTF-8'
        : 'application/json';

    const responsePromise = getHttpClient()(url, {
        method: 'POST',
        headers: {
            'Content-Type': contentType
        },
        body: postBody,
    });
    return handleResponse(responsePromise, successEvent, errorEvent);
}

function getHttpClient() {
    return httpClient || globalThis.fetch;
}

interface GetPayload {
    url: string,
    params?: {},
    successEvent?: SuccessEvent,
    errorEvent?: ErrorEvent,
}

export class GetEvent implements WhitelabelEventWithPayload<GetPayload> {
    payload: GetPayload;
    type: string;
    static readonly TYPE: string = 'http-get';

    constructor(payload: GetPayload) {
        this.type = GetEvent.TYPE;
        this.payload = payload;
    }
}

export function httpGetEventHandler(event: WhitelabelEvent) {
    const {payload: {url, params, successEvent, errorEvent}} = event as GetEvent;
    const paramsAsSearchParamsString = params ? `?${new URLSearchParams(params)}` : '';
    const responsePromise = httpClient(`${url}${paramsAsSearchParamsString}`);
    return handleResponse(responsePromise, successEvent, errorEvent)
}

function init(fc: any = globalThis.fetch, ev: any = EventBus.getInstance()) {
    eventBus = ev;
    httpClient = fc;
    eventBus.subscribe(GetEvent.TYPE, httpGetEventHandler)
    eventBus.subscribe(PostEvent.TYPE, httpPostEventHandler)
}

export default {
    GetEvent,
    PostEvent,
    init,
}

function handleResponse(
    responsePromise: Promise<Response>,
    successEvent: SuccessEvent | undefined,
    errorEvent: ErrorEvent | undefined,
) {
    return responsePromise
        .then(res => {
            if (res.ok || res.redirected) return res;
            throw new Error(res.statusText);
        })
        .then(response => response?.text())
        .then((data) => {
            if(successEvent == null) {
                return;
            }

            const dataToParse = data && data != '' ? data : null;
            eventBus.emit(new successEvent.eventClass(
                {
                    response: parseData(dataToParse),
                    ...successEvent.payload
                }));
        })
        .catch(reason => {
            if (errorEvent == null) {
                throw reason;
            }

            eventBus.emit(new errorEvent.eventClass({
                response: reason,
                ...errorEvent.payload,
            }));
        });
}

function parseData(dataToParse: string | null) {
    try {
        return parseJSON(dataToParse);
    } catch (error) {
        if (error instanceof SyntaxError) {
            return parseText(dataToParse);
        } else {
            throw error;
        }
    }
}

function parseJSON(parsingData: string | null) {
    return parsingData ? JSON.parse(parsingData) : {};
}

function parseText(parsingData: string | null) {
    return parsingData ? parsingData : '';
}